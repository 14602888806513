<template>
  <div v-frag>
    <invoice-alert-msg :item="obInvoice" />
    <alert-ucfe-warning v-if="!signed" />
    <alert-max-ui
      v-if="!signed"
      :alert-type="sAlertType"
      :display="!canSign && !!fSubtotal"
    />

    <v-form v-if="bMounted" class="fill-height" @submit.prevent>
      <gw-form-observer
        :hide-save-action="signed"
        :loading="isLoading"
        hide-top-actions
        compact
        @cancel="onClose"
        @save="onSave"
      >
        <template #bottom-actions="{ invalid, validate }">
          <v-avatar v-if="invalid" class="mr-4" color="warning" size="32">
            <v-icon dark small>mdi-alert-outline</v-icon>
          </v-avatar>

          <references-sync-dialog v-model="bRefDialog" :invoice="obInvoice" />

          <v-btn
            v-if="inView"
            :disabled="bDisableButtons"
            :small="isMobile"
            :to="updateUrl"
            color="primary"
            depressed
          >
            {{ $t("edit") }}
          </v-btn>

          <invoice-pdf-print v-if="canPrint" :item="obInvoice" />

          <v-btn
            v-if="canProcess"
            :disabled="!isProcessable"
            :loading="loadingProcess"
            :small="isMobile"
            color="primary"
            depressed
            @click="onProcess"
          >
            {{ $t("process.received.invoice") }}
          </v-btn>

          <v-btn
            v-else-if="!signed && !obInvoice.id"
            :disabled="!canIssue || invalid || bDisableButtons"
            :small="isMobile"
            color="primary"
            depressed
            @click="onSaveAndIssue(validate)"
          >
            {{ $t("save.and.issue") }}
          </v-btn>

          <v-btn
            v-if="canIssue && !signed && !!obInvoice.id"
            :disabled="invalid || bDisableButtons"
            :small="isMobile"
            color="primary"
            depressed
            @click="onSaveAndIssue(validate)"
          >
            {{ $t("sign") }}
          </v-btn>

          <v-btn
            v-if="inView"
            :disabled="invalid || bDisableButtons"
            :small="isMobile"
            color="primary"
            depressed
            @click="onSign()"
          >
            {{ $t("sign") }}
          </v-btn>
        </template>

        <settings
          v-model="obModel"
          :hide-movement-type-select="hideMovementTypeSelect"
          :read-only="signed"
          v-bind="$attrs"
        />
        <invoice-signing-message :value="signing" />
        <invoice-customer-email
          v-model="bCustomerEmailDialog"
          @send="onContinueRya"
        />
      </gw-form-observer>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesMixin from "@/modules/invoices/mixins/InvoicesMixin";

import type {
  CompanySettingsData,
  Invoice,
  InvoiceData,
  InvoicePositionData,
} from "@planetadeleste/vue-mc-gw";
import {
  InvoicePosition,
  InvoiceReference,
  InvoiceType,
  Label,
  PaymentMethod,
} from "@planetadeleste/vue-mc-gw";
import { InvoiceModule } from "@/store/invoice";
import { ConfigModule } from "@/store/config";
import { Offer } from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";

import Settings from "@/modules/invoices/components/tabs/Settings.vue";
import AlertUcfeWarning from "@/modules/invoices/components/AlertUcfeWarning.vue";
import AlertMaxUi from "@/modules/invoices/components/AlertMaxUi.vue";
import ReferencesSyncDialog from "@/modules/invoices/components/references/ReferencesSyncDialog.vue";
import InvoiceSigningMessage from "@/modules/invoices/components/InvoiceSigningMessage.vue";
import { delay, get, isString, isUndefined, map, set } from "lodash";
import { array, number, object, string, ValidationError } from "yup";
import InvoicePdfPrint from "@/modules/invoices/components/InvoicePdfPrint.vue";
import { EventBus } from "@/services/event-bus";
import type { RouteNamedMap } from "@/types/typed-router";
import useInvoice from "@/composables/invoice";
import { TYPE } from "vue-toastification";
import InvoiceAlertMsg from "@/modules/invoices/components/InvoiceAlertMsg.vue";
import { canModuleAccess } from "@/services/moduleAccess";
import InvoiceCustomerEmail from "@/modules/invoices/components/InvoiceCustomerEmail.vue";
import type { FirmData } from "@planetadeleste/vue-mc-gw/src/types";

@Component({
  components: {
    InvoiceCustomerEmail,
    InvoiceAlertMsg,
    InvoicePdfPrint,
    Settings,
    AlertUcfeWarning,
    AlertMaxUi,
    ReferencesSyncDialog,
    InvoiceSigningMessage,
  },
})
export default class InvoiceForm extends Mixins(InvoicesMixin) {
  bMounted = false;
  bRefDialog = false;
  bCustomerEmailDialog = false;
  bDisableButtons = false;
  bSendWithoutEmail = false;
  loadingProcess = false;

  get obInvoice() {
    return InvoiceModule.invoice;
  }

  get signed(): boolean {
    return InvoiceModule.signed || this.signing;
  }

  get iMovementType() {
    return InvoiceModule.iMovementType;
  }

  get sTypeCode() {
    return InvoiceModule.sTypeCode;
  }

  get invoicePaymentMethods() {
    return InvoiceModule.invoicePaymentMethods;
  }

  get obInvoiceCustomerData(): FirmData {
    return this.obInvoice.get("customer_firm");
  }

  get signing(): boolean {
    return (
      !!InvoiceModule.signing.length &&
      !!this.obInvoice.id &&
      InvoiceModule.signing.includes(this.obInvoice.id)
    );
  }

  get updateUrl() {
    if (!this.obInvoice.id) {
      return undefined;
    }

    const arRouteName = ["invoices"];
    switch (this.$route.name as keyof RouteNamedMap) {
      case "invoices.update":
      case "invoices.create":
      case "invoices.copy":
      case "invoices.correct":
      case "invoices.view":
        arRouteName.push("update");
        break;

      case "invoices.receipt.update":
      case "invoices.receipt.create":
        arRouteName.push("receipt");
        arRouteName.push("update");
        break;

      case "invoices.debt.update":
      case "invoices.debt.create":
      case "invoices.debt.view":
        arRouteName.push("debt");
        arRouteName.push("update");
        break;
    }

    return { name: arRouteName.join("."), params: { id: this.obInvoice.id } };
  }

  get sUUID(): string | undefined {
    return this.obInvoice.get("uuid");
  }

  get canPrint(): boolean {
    return !this.isDebt && !this.isReceived && !!this.sUUID && this.signed;
  }

  get canProcess() {
    return (
      this.isReceived &&
      !this.isProcessed &&
      canModuleAccess(["accounting", "stocks"], true)
    );
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  get sAlertType() {
    return this.sTypeCode === InvoiceType.CODE_ERESGUARDO
      ? "customer"
      : "maxui";
  }

  get fSubtotal() {
    return InvoiceModule.fSubtotal;
  }

  get fTotalRounded() {
    return InvoiceModule.fTotalRounded;
  }

  get fPaid() {
    return InvoiceModule.fPaid;
  }

  get isDebt() {
    return InvoiceModule.isDebt;
  }

  get isDebtRYA() {
    return InvoiceModule.isDebtRYA;
  }

  get isReceived(): boolean {
    return InvoiceModule.isReceived;
  }

  /**
   * Get true if invoice is type BUY|PAY and has ben processed
   */
  get isProcessed(): boolean {
    return this.obInvoice.get("is_processed", false);
  }

  /**
   * Get true if invoice is type BUY|PAY and can set to processed
   */
  get isProcessable(): boolean {
    return this.obInvoice.get("is_processable", false);
  }

  get company() {
    return AppModule.company;
  }

  get companySettings(): CompanySettingsData | undefined {
    return AppModule.companySettings;
  }

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.before.save`, (obModel: Invoice) => {
      obModel.unset(["customer", "price_list", "invoice_movement_type"]);

      // Map positions to JSON objects
      const arPositions = map(obModel.get("positions", []), (obPosition) => {
        const obItemData: InvoicePositionData =
          obPosition instanceof InvoicePosition
            ? obPosition.attributes
            : obPosition;

        // Set offer json data
        const obOffer = obItemData.offer;
        if (obOffer && obOffer instanceof Offer) {
          set(obItemData, "offer", obOffer.attributes);
        }

        // Set labels to JSON data
        const arLabels: Label[] = get(obItemData, "labels", []);
        if (arLabels.length) {
          const arPositionLabels = map(arLabels, (obLabel) => {
            return obLabel instanceof Label ? obLabel.attributes : obLabel;
          });
          set(obItemData, "labels", arPositionLabels);
        }

        return obItemData;
      });

      // Map payment_methods to JSON
      const arPaymentMethods = map(
        obModel.get("payment_methods", []),
        (obModel) => {
          if (obModel.payment_method instanceof PaymentMethod) {
            set(obModel, "payment_method", obModel.payment_method.attributes);
          }
          return obModel;
        }
      );

      // Map references
      const arReferences = map(obModel.get("references", []), (obReference) => {
        return obReference instanceof InvoiceReference
          ? obReference.attributes
          : obReference;
      });

      // Map labels
      const arLabels = map(obModel.get("labels", []), (obLabel: Label) => {
        return obLabel instanceof Label ? obLabel.attributes : obLabel;
      });

      obModel.set("references", arReferences);
      obModel.set("positions", arPositions);
      obModel.set("payment_methods", arPaymentMethods);
      obModel.set("labels", arLabels);
      //TODO validar por tipo de comprobante

      //para cobranzas marcar como no is_cash
      if (this.isDebt) {
        obModel.set("is_cash", false);
      }
    });
    this.addEvent(
      `${this.sModelName}.after.save`,
      (obModelData: InvoiceData) => {
        this.bDisableButtons = false;

        if (
          this.companySettings?.print_after_billing &&
          obModelData.is_signed
        ) {
          EventBus.emit("invoice.print", obModelData);
        }
      }
    );
  }

  async onMounted() {
    try {
      this.loading();

      if (AppModule.branch) {
        this.obModel.set("branch_id", AppModule.branch.id);
      }

      await InvoiceModule.loadTaxTypes();
      await ConfigModule.loadCompanyCurrencyRates();
    } catch (error) {
      this.processError(error);
    } finally {
      this.bMounted = true;
      this.loaded();
    }
  }

  onBeforeDestroy() {
    this.bMounted = false;
  }

  async onSave() {
    this.$emit("input", this.obInvoice);
    this.obInvoice.set("sign_only", false);
    this.obInvoice.set("issue_invoice", false);
    await this.onSaveDelayed();
  }

  async onSaveAndIssue(fnValidate?: () => Promise<boolean>) {
    if (!isUndefined(fnValidate)) {
      const valid = await fnValidate();
      if (!valid) {
        return;
      }
    }

    this.obInvoice.set("issue_invoice", true);
    this.$emit("input", this.obInvoice);
    await this.onSaveDelayed();
  }

  async onSign() {
    this.obInvoice.set("sign_only", true);
    this.$emit("input", this.obInvoice);
    await this.onSaveDelayed();
  }

  async onContinueRya() {
    this.bSendWithoutEmail = true;
    await this.onSaveDelayed();
  }

  async onSaveDelayed() {
    if (
      this.isDebtRYA &&
      !this.obInvoiceCustomerData?.email &&
      !this.bSendWithoutEmail
    ) {
      this.bCustomerEmailDialog = true;
      return;
    }

    const sResponse = await this.validatePaymentMethods();

    if (isString(sResponse)) {
      this.$toast.error(sResponse);
      return;
    }

    InvoiceModule.setTotals();
    this.bDisableButtons = true;
    delay(this.save, 300);
  }

  async onProcess() {
    const { obj } = useInvoice(this.obInvoice);

    if (!obj) {
      return;
    }

    this.loadingProcess = true;
    const {
      data: obInvoiceData,
      message,
      status,
    } = await obj.updateProcessed();

    EventBus.emit("invoice.received.after.processed", obInvoiceData);

    if (obInvoiceData && get(obInvoiceData, "is_processed")) {
      this.obInvoice.set("is_processed", true);
    }

    if (message) {
      this.$toast(message, { type: status ? TYPE.SUCCESS : TYPE.ERROR });
    }

    this.loadingProcess = false;
  }

  async validatePaymentMethods(): Promise<boolean | string> {
    if (this.signed || this.inView || !this.fPaid) {
      return true;
    }

    let obSchema = number().label(this.$t("invoice.payment.method") as string);

    if (!this.isDebt) {
      obSchema.min(0);
    }

    if (this.fTotalRounded) {
      obSchema.test(
        "paid",
        (label) => `El valor de ${label} no coincide con ${this.fTotalRounded}`,
        (value) => value === this.fTotalRounded
      );
    }

    try {
      if (
        this.sTypeCode === InvoiceType.CODE_ERECIBO &&
        canModuleAccess("accounting")
      ) {
        // @ts-expect-error
        obSchema = object({
          paid: obSchema,
          payment_methods: array()
            .label(this.$t("invoice.payment.method") as string)
            .of(
              object({
                config: object({
                  account_code: string().required(
                    this.$t(
                      "error.code.account.required.in.payment.methods"
                    ) as string
                  ),
                }),
              })
            ),
        });
        await obSchema.validate({
          paid: this.fPaid,
          payment_methods: this.invoicePaymentMethods,
        });
      } else {
        await obSchema.validate(this.fPaid);
      }
      return true;
      // @ts-ignore
    } catch (err: ValidationError) {
      return err.errors[0];
    }
  }

  async onClose() {
    let sRouteName = this.signed && !this.inView ? "emitted" : "saved";

    if (this.isDebt) {
      sRouteName = `debt.${sRouteName}`;
    } else if (this.isReceived) {
      sRouteName = `received.${
        this.obInvoice.get("is_processed") ||
        !canModuleAccess(["accounting", "stocks"], true)
          ? "list"
          : "unprocessed"
      }`;
    } else if (this.$route.name) {
      switch (this.$route.name as keyof RouteNamedMap) {
        case "invoices.receipt.update":
        case "invoices.receipt.create":
          sRouteName = `receipt.${sRouteName}`;
          break;

        case "invoices.debt.update":
        case "invoices.debt.create":
        case "invoices.debt.view":
          sRouteName = `debt.${sRouteName}`;
          break;
      }
    }

    return await this.$router.push({ name: `invoices.${sRouteName}` });
  }
}
</script>
